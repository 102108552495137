@import url('https://fonts.googleapis.com/css2?family=Coiny&display=swap');

.login {
    width: 400px;
    background-color: #ccc;
    padding: 3rem;
    border-radius: 10px;
}
.login input {
    width: 100%;
}

.logo {
    font-family: 'Coiny', cursive;
    font-size: 50px;
    text-align: center;
    text-shadow: 0 1px 2px #000000a0;
}

.logo span {
    display: inline-block;
    color: #00c4b0;
    transform-origin: 50% 70%;
}

.logo span:nth-child(2n) {
    color: #ffb700;
}

.logo span:nth-child(1) {
    animation: flipper 0.8s cubic-bezier(0.68, -0.55, 0.26, 1.55) 0.4s both, fadeIn 1s both;
}

.logo span:nth-child(2) {
    animation: flipper 0.8s cubic-bezier(0.68, -0.55, 0.26, 1.55) 0.7s both, fadeIn 1.1s both;
}

@keyframes flipper {
    from { transform: rotateX(90deg) }
    to { transform: rotateX(0deg) }
}